import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Layout from '../../layouts/mdx';
export const _frontmatter = {
  "product": "verify",
  "title": "Use eID in your applications"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`For an introduction to Criipto Verify and eID, you may want to read `}<a parentName="p" {...{
        "href": "/verify/getting-started/overview"
      }}>{`the overview`}</a>{` in the Getting Started section.`}</p>
    <p>{`Criipto Verify supports two distinct use cases supported by most eID solutions:`}</p>
    <p><strong parentName="p">{`Authentication`}</strong></p>
    <p>{`All eID services supports user authentication. With Criipto Verify you integrate using `}<em parentName="p">{`OpenID Connect`}</em>{` (aka OIDC). A general introduction to OIDC with Criipto Verify is available in the section on `}<a parentName="p" {...{
        "href": "/verify/getting-started/oidc-intro"
      }}>{`Using OIDC`}</a>{`.`}</p>
    <p><strong parentName="p">{`Digital Signatures`}</strong></p>
    <p>{`Due to the underlying use of asymmetric encryption by some eID services, like the Norwegian and Swedish BankID,
digitally signing text is also supported through a proprietary Criipto API. `}</p>
    <ul>
      <li parentName="ul">{`SE BankID: offers signing via `}<a parentName="li" {...{
          "href": "/verify/e-ids/swedish-bankid/#signing-textmarkdown"
        }}>{`specific OIDC parameters`}</a></li>
      <li parentName="ul">{`NO BankID: More documentation on this will soon follow,
but we have an example of how to program both plain `}<a parentName="li" {...{
          "href": "https://github.com/criipto/docs-old/blob/master/signatures/dotnet-core-v3.md#plain-text-signature"
        }}>{`text signing and PDF signing into your ASP.NET Core app`}</a></li>
    </ul>
    <p>{`If you do not find the documentation you need, please do not hesitate to drop us a note at `}<a parentName="p" {...{
        "href": "mailto:support@criipto.com"
      }}>{`support@criipto.com`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      